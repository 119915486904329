import React from 'react'
import Router from 'next/router'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    '& .title': {
      fontSize: '280px'
    },
    '& .pagename': {
      textDecoration: 'underline',
      fontWeight: 'bold'
    }
  }
})

export default function Custom404() {
  const classes = useStyles()

  return (
    <div className={clsx(['__aligned_center', classes.root])}>
      <div className="title">404</div>
      <div>
        Page <span className="pagename">{Router.asPath}</span> non trouvée :(
      </div>
    </div>
  )
}
